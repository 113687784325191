<template>
  <div dir="ltr" class="Product-page" style="overflow-x: hidden">
    <div class="" v-if="isProductLoading">
      <EditProductSkeleton />
    </div>
    <div v-else>
      <Header
        :back="backUrl"
        heading="Edit"
        :subHeading="(' ' + currentProduct.title).slice(1)"
      >
        <template v-slot:buttons>
          <div class="d-flex align-items-center">
            <button @click="openDuplicateModal" class="duplicateProducts">
              <!--  -->
              <span>
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 16H6.5C5.39543 16 4.5 15.1046 4.5 14V6C4.5 4.89543 5.39543 4 6.5 4H14.5C15.6046 4 16.5 4.89543 16.5 6V8M10.5 20H18.5C19.6046 20 20.5 19.1046 20.5 18V10C20.5 8.89543 19.6046 8 18.5 8H10.5C9.39543 8 8.5 8.89543 8.5 10V18C8.5 19.1046 9.39543 20 10.5 20Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                Duplicate
              </span>
            </button>
          </div>
        </template>
      </Header>

      <div class="row card-body pb-4 mb-5 text-left">
        <!-- Center Section Start -->
        <div class="col-12 col-sm-12 col-md-12 col-lg-8 mt-3 mt-lg-0">
          <!-- Center  Section Start -->
          <div class="row card border-radius py-3">
            <!-- Inner Content Start -->
            <div class="col-12 col-sm-12 col-md-12">
              <div class="row">
                <!-- Title Start -->
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <p class="headText m-0 mb-1">
                    Title <span style="color: red">*</span>
                  </p>
                  <b-form-input
                    v-model="currentProduct.title"
                    placeholder="Enter product name"
                  ></b-form-input>
                </div>
                <!-- Title End -->
                <!-- Description Start -->
                <div class="mt-3 col-12 col-sm-12 col-md-12 col-lg-12">
                  <p class="headText m-0 mb-1">Description</p>

                  <Editor
                    style="border-bottom: 1px solid var(--border-color)"
                    :productDescription="currentProduct.description"
                    placeholder="write something...."
                    @updateDescription="updateProductDescription($event)"
                  />
                </div>

                <!-- Description End -->
                <!-- Price Start -->
                <div class="mt-3 col-12">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-4">
                      <p class="headText m-0 mb-1">Price</p>
                      <b-form-input
                        type="number"
                        placeholder="00.00"
                        v-model="currentProduct.price"
                      ></b-form-input>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 mt-2 mt-md-0">
                      <p class="headText m-0 mb-1">Compared Price</p>
                      <b-form-input
                        placeholder="00.00"
                        type="number"
                        v-model="currentProduct.compare_at_price"
                      ></b-form-input>
                    </div>
                  </div>
                </div>
                <!-- Price End -->
              </div>
            </div>
          </div>
          <ProductMediaCard
            class="row flex-column mt-3"
            :images="productImages"
            :uploadImageLink="uploadImageLink"
            :isLoadImageAdding="isImageAdding"
            :isLoadingDeleteImage="isImageDelete"
            @selectedImages="(e) => (selectedProductImage = e)"
            @updateImageDrag="(e) => (currentProduct.productImages = e)"
            @delete="deleteImages()"
            :uploadImage="uploadFile"
          ></ProductMediaCard>

          <div
            v-if="false"
            class="row bg-white"
            style="border: 1px solid rgba(0, 0, 0, 0.125); min-height: 350px"
          >
            <div class="col-12">
              <p class="headText m-0 mb-2">Media</p>
            </div>

            <div
              class="col-12 d-flex justify-content-between align-items-center"
              v-if="selectedProductImage.length"
            >
              <div>
                <b-form-checkbox
                  id="SelectAllImage"
                  @change="selectAllImage($event)"
                  name="SelectAllImage"
                >
                  Select All Images
                </b-form-checkbox>
              </div>
              <div>
                <button
                  v-if="selectedProductImage.length == 1"
                  class="btn text-success"
                  @click="MakeThumbnailImage"
                >
                  <span class="text-success" v-if="!makeThumbnailSpinner">
                    Make Thumbnail</span
                  >
                  <div
                    v-else
                    class="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
                <button class="btn text-danger" @click="deleteImages">
                  <span class="text-danger" v-if="!isImageDelete">Delete</span>
                  <div
                    v-else
                    class="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
              </div>
            </div>
            <!-- Main Product Image start  -->
            <div
              class="col-12 col-md-6 col-lg-6 col-xl-4"
              v-if="currentProduct.productImages.length && getFirstImage"
            >
              <div
                class="card ImageCard shadow-sm d-flex align-items-center justify-content-center"
                style="height: 350px; width: 100%; position: relative"
              >
                <!-- @click="openGallery('AddProductMainImage',currentProduct.image)" -->

                <video
                  v-if="checkFileType(getFirstImage.image) == 'video'"
                  ref="videoRef"
                  :src="`${getFirstImage.image}`"
                  style="object-fit: cover; border-radius: 6px"
                  width="90%"
                  height="90%"
                  controls
                ></video>
                <LazyImage
                  v-else
                  :key="getFirstImage.id"
                  :src="getFirstImage.image"
                  style="
                    width: 90%;
                    height: 90%;
                    object-fit: cover;
                    border-radius: 6px;
                  "
                  alt=""
                />
                <b-form-checkbox-group
                  class="checkboxElement"
                  :id="`EditProductImageCheck-${getFirstImage.id}`"
                  style="position: absolute; top: 5%; left: 6%"
                  :style="
                    selectedProductImage.length
                      ? 'display:block !important;'
                      : null
                  "
                  v-model="selectedProductImage"
                  :name="`EditProductImageCheck-${getFirstImage.id}`"
                >
                  <b-form-checkbox :value="getFirstImage"></b-form-checkbox>
                </b-form-checkbox-group>
              </div>
            </div>
            <!-- Main Product Image end  -->
            <!-- Other Images Start -->
            <div class="col-12 col-md-6 col-lg-6 p-0">
              <!-- Other Images -->
              <div class="row m-0 mt-3">
                <div
                  class="col-6 col-md-6 col-lg-6 col-xl-4 mb-2"
                  v-for="(image, index) in currentProduct.productImages"
                  :key="index"
                  v-show="
                    currentProduct.productImages.length &&
                    image.image !== getFirstImage.image
                  "
                >
                  <div
                    class="card ImageCard shadow-sm otherImageDiv d-flex align-items-center justify-content-center"
                    style="
                      border-radius: 6px;
                      cursor: pointer;
                      height: 150px;
                      width: 150px;
                      position: relative;
                    "
                  >
                    <video
                      v-if="checkFileType(image.image) == 'video'"
                      ref="videoRef"
                      :src="`${image.image}`"
                      style="object-fit: cover; border-radius: 6px"
                      width="90%"
                      height="90%"
                      controls
                    ></video>
                    <LazyImage
                      v-else
                      :key="`${image.id}-${image.image}`"
                      :src="image.image"
                      style="
                        width: 90%;
                        height: 90%;
                        object-fit: cover;
                        border-radius: 6px;
                      "
                      alt=""
                    />
                    <b-form-checkbox-group
                      class="checkboxElement"
                      :id="`EditProductImageCheck-${image.id}`"
                      style="position: absolute; top: 5%; left: 6%"
                      :style="
                        selectedProductImage.length
                          ? 'display:block !important;'
                          : null
                      "
                      v-model="selectedProductImage"
                      :name="`EditProductImageCheck-${image.id}`"
                    >
                      <b-form-checkbox :value="image"></b-form-checkbox>
                    </b-form-checkbox-group>
                  </div>
                </div>
                <!-- Add Image Start -->

                <div class="col-6 col-md-6 col-lg-6 col-xl-4">
                  <div
                    class="card shadow-sm otherImageDiv"
                    style="
                      border-radius: 6px;
                      color: #cbc6cf;
                      height: 150px;
                      width: 150px;
                    "
                  >
                    <div
                      v-if="!isImageAdding"
                      class="d-flex flex-column align-items-center justify-content-around h-100"
                    >
                      <!-- ADD start -->
                      <div class="d-flex flex-column">
                        <button
                          class="btn text-white"
                          style="background: #4d1b7e"
                          @click="$refs.ImageFileInput.click()"
                        >
                          <span style="font-weight: 600">Add </span>
                        </button>

                        <input
                          type="file"
                          accept="image/*;video/*"
                          style="display: none"
                          ref="ImageFileInput"
                          multiple
                          @change="uploadFile"
                        />
                      </div>
                      <!-- Add End -->
                      <!-- ADD URL start -->
                      <div class="">
                        <button class="btn" @click="OpenImageUrlModal">
                          <span
                            style="
                              color: #4d1b7e;

                              text-decoration: underline;
                            "
                            >Add from URL</span
                          >
                        </button>
                      </div>
                      <!-- ADD URL start -->
                    </div>

                    <div
                      v-else
                      class="d-flex h-100 align-items-center justify-content-center"
                    >
                      <div
                        class="spinner-border spinner-border"
                        style="color: #4d1b7e"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Add Image End -->
              </div>
            </div>
            <!-- Other Images End -->
          </div>

          <div class="row card border-radius py-3 mt-3 d-none">
            <div v-if="hasVariantAlready" class="pr-5 pl-5">
              <span class="headText" style="color: #000000"
                >Add New variants</span
              >
              <button
                class="btn px-3 py-2"
                style="background: #4d1b7e; border-radius: 6px; float: right"
                @click="createProductVariantObject"
              >
                <span class="text-white"> Add</span>
              </button>
            </div>
            <div v-else class="col-12 col-sm-6 col-md-12">
              <div class="mt-3">
                <div>
                  <b-form-checkbox
                    name="OptionCheck"
                    v-model="currentProduct.optionCheckStatus"
                    value="1"
                    unchecked-value="0"
                    :checked="currentProduct.optionCheckStatus"
                  >
                    <span class="headText" style="color: #000000"
                      >This product has options, like size or color</span
                    >
                  </b-form-checkbox>
                </div>

                <div class="mt-3" v-if="currentProduct.optionCheckStatus == 1">
                  <draggable
                    v-model="currentProduct.optionsList"
                    v-bind="{ animation: 0, ghostClass: 'ghost' }"
                    @start="OptionDragging = true"
                    @end="OptionDragging = false"
                    style="cursor: move"
                  >
                    <div
                      class="mb-3"
                      v-for="(option, index) in currentProduct.optionsList"
                      :key="index"
                    >
                      <div class="p-3 w-75 card">
                        <div class="100">
                          <div
                            class="w-100 d-flex justify-content-end"
                            v-if="currentProduct.optionsList.length > 1"
                          >
                            <button class="btn" @click="deleteOption(index)">
                              <i
                                class="text-danger fa fa-trash-o"
                                aria-hidden="true"
                                style="font-size: 20px"
                              ></i>
                            </button>
                          </div>
                        </div>
                        <div class="w-75">
                          <div class="d-flex align-items-center">
                            <i
                              class="fa fa-bars mr-3"
                              style="color: #4d4950"
                              aria-hidden="true"
                            ></i>
                            <p class="headText m-0 mb-1">Option Name</p>
                          </div>

                          <b-form-input
                            placeholder="Enter option name"
                            v-model="option.title"
                          ></b-form-input>
                        </div>
                        <div class="mt-2 w-75">
                          <p class="headText m-0 mb-1">Option Value</p>
                          <draggable
                            v-model="option.value"
                            @start="OptionValueDragging = true"
                            @end="OptionValueDragging = false"
                            style="cursor: move"
                          >
                            <div
                              class="mb-2 d-flex align-items-center"
                              v-for="(optionValue, valueIndex) in option.value"
                              :key="valueIndex"
                            >
                              <i
                                class="fa fa-bars mr-3"
                                style="color: #4d4950"
                                aria-hidden="true"
                              ></i>
                              <b-form-input
                                placeholder="Enter option value"
                                v-model="optionValue.value"
                              ></b-form-input>
                              <div class="" v-if="option.value.length > 1">
                                <button
                                  class="btn"
                                  @click="deleteOptionValue(index, valueIndex)"
                                >
                                  <i
                                    class="fa fa-trash-o"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </draggable>

                          <div class="mt-2">
                            <button
                              class="btn ml-3"
                              @click="AddOptionsValue(index)"
                            >
                              <span class="AddOption"
                                ><i
                                  class="fa fa-plus mr-2"
                                  aria-hidden="true"
                                ></i>
                                Add Option Value</span
                              >
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </draggable>

                  <div class="mt-3 d-flex align-items-center">
                    <button
                      class="btn mr-5"
                      @click="AddOptions"
                      v-if="currentProduct.optionsList.length < 3"
                    >
                      <span class="AddOption"
                        ><i class="fa fa-plus mr-2" aria-hidden="true"></i> Add
                        Option</span
                      >
                    </button>
                    <button
                      class="btn px-3 py-2"
                      style="background: #4d1b7e; border-radius: 6px"
                      @click="CreateVariants"
                    >
                      <span class="text-white"> Create Variants</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row mt-3 card border-radius py-3 d-none"
            v-if="
              currentProduct.optionCheckStatus && currentProduct.variants.length
            "
          >
            <div class="col-12 col-sm-6 col-md-12">
              <!-- Variant Start -->

              <div class="mt-3">
                <b-table
                  responsive
                  :items="currentProduct.variants"
                  :fields="fields"
                >
                  <template #cell(image)="data">
                    <div
                      v-if="!data.item.image"
                      style="width: 60px; height: 60px; cursor: pointer"
                      class="d-flex align-items-center justify-content-center"
                      @click.stop="
                        OpenEditVaraintImageGallery(
                          'EditVariantImages',
                          data.index
                        )
                      "
                    >
                      <LazyImage
                        :src="'https://images.quizell.com/website/default_start_page.png'"
                        alt=""
                        style="
                          height: 100%;
                          width: 100%;
                          object-fit: cover;
                          border-radius: 6px;
                        "
                      />
                    </div>
                    <div
                      v-else
                      style="width: 60px; height: 60px; cursor: pointer"
                      class=""
                      @click.stop="
                        OpenEditVaraintImageGallery(
                          'EditVariantImages',
                          data.index,
                          data.item.image
                        )
                      "
                    >
                      <LazyImage
                        :key="data.item.image"
                        :src="ImageSrc(data.item.image)"
                        alt=""
                        style="height: 100%; width: 100%; border-radius: 6px"
                      />
                    </div>
                  </template>
                  <template #cell(title)="data">
                    <b-form-input
                      v-model="data.item.title"
                      type="text"
                      required
                      placeholder="Variant Title"
                    />
                  </template>
                  <template #cell(price)="data">
                    <b-form-input
                      v-model="data.item.price"
                      type="number"
                      required
                      placeholder="00.00"
                    />
                  </template>
                  <template #cell(compare_at_price)="data">
                    <b-form-input
                      v-model="data.item.compare_at_price"
                      type="number"
                      required
                      placeholder="00.00"
                    />
                  </template>
                  <template #cell(action)="data">
                    <button
                      class="btn px-2 py-2 deleteProduct mx-2"
                      @click="deleteProductVariant(data.item)"
                    >
                      Delete
                      <i class="ml-2 fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </template>
                </b-table>
              </div>
              <!-- Variant End -->
            </div>
          </div>
          <!-- Inner Content End -->
        </div>
        <!-- Center Section Start -->

        <!-- Right Section Start -->
        <div
          class="col-sm-12 col-md-12 col-lg-4 pr-0 mt-3 mt-lg-0"
          style="height: fit-content"
        >
          <div class="card border-radius px-3 pb-4">
            <!-- Collections & tags Start -->
            <div class="mt-3">
              <div>
                <p class="headText m-0 mb-1">Status</p>
                <b-form-group
                  class="text-dark font-weight-bold SelectFormGroup"
                >
                  <b-form-select v-model="currentProduct.isActive" class="">
                    <b-form-select-option :value="true"
                      >Active</b-form-select-option
                    >
                    <b-form-select-option :value="false"
                      >Disabled</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
              </div>
              <!-- Detail Links -->
              <div class="my-3">
                <p class="headText m-0 mb-1">Link for details</p>
                <b-form-input
                  placeholder="Enter link"
                  v-model="currentProduct.detailLink"
                ></b-form-input>
              </div>
              <!-- SKU -->
              <div class="mb-2">
                <p class="headText m-0 mb-1">SKU</p>
                <b-form-input
                  placeholder="Enter SKU"
                  v-model="currentProduct.sku"
                ></b-form-input>
              </div>

              <!-- SKU -->
              <!-- Quantity -->
              <!-- <div class="mb-2 QuantityInput">
                  <p class="headText m-0 mb-1">Quantity</p>
                  <b-form-input
                  type="number"
                    placeholder="Enter Quantity"
                    v-model="currentProduct.quantity"
                  ></b-form-input>
                   <div class="d-flex QtyBtn flex-column">
                           <button class="btn increaseQuantityBtn font-weight-bold" @click="currentProduct.quantity++">
                          <b-icon-caret-up-fill class="font-weight-bold"></b-icon-caret-up-fill>
                        </button>
                           <button class="btn decreaseQuantityBtn" @click="currentProduct.quantity--">
                           <b-icon-caret-down-fill class="font-weight-bold"></b-icon-caret-down-fill>
                        </button>
                        </div>
                </div> -->
              <div>
                <p class="headText m-0 mt-3">Collections</p>
                <MultiFilter
                  :selectedItems="selectedCollections"
                  :isLoading="isProductLoading"
                  :items="allCollections"
                  @input="(e) => (currentProduct.collections = e)"
                />

                <!-- <b-form-tags
                      v-model="currentProduct.collections"
                      placeholder="Add Collections"
                    ></b-form-tags> -->
              </div>
              <div class="mt-3">
                <p class="headText m-0 mb-1">Tags</p>
                <MultiFilter
                  :selectedItems="selectedTags"
                  :addInList="true"
                  :isLoading="isProductLoading"
                  :items="allTags"
                  @add="AddTag"
                  @input="
                    (e) =>
                      (currentProduct.tags = [...e.map((tag) => tag.title)])
                  "
                />

                <!-- <b-form-tags
                      v-model="currentProduct.tags"
                      placeholder="Add Tags"
                    ></b-form-tags> -->
              </div>
              <div class="mt-3">
                <p class="headText m-0 mb-1">Bullet Description</p>
                <MultiFilter
                  :selectedItems="selectedBullets"
                  :addInList="true"
                  :isLoading="isProductLoading"
                  :items="allBullets"
                  @input="
                    (e) =>
                      (currentProduct.bullet_description = [
                        ...e.map((bullet) => bullet.title),
                      ])
                  "
                  @add="addBullet($event)"
                ></MultiFilter>
              </div>
            </div>
            <!-- Collections & tags End -->

            <!-- Quantity -->
            <div class="mt-3">
              <p class="headText m-0 mb-1">Vendor</p>
              <MultiFilter
                :singleSelect="true"
                :selectedSingle="selectedVendors"
                :isLoading="isProductLoading"
                :items="allVendors"
                @input="(e) => (currentProduct.vendor = e)"
              />
            </div>

            <!-- Detail Links -->
            <div class="mt-3" v-if="productCategories.length">
              <div class="d-flex justify-content-between align-items-center">
                <p class="headText m-0">Categories</p>
                <button class="btn">Add</button>
              </div>

              <b-form-group class="text-dark font-weight-bold SelectFormGroup">
                <b-form-select v-model="currentProduct.category_id" class="">
                  <b-form-select-option :value="null"
                    >Select Category</b-form-select-option
                  >
                  <b-form-select-option
                    v-for="category in productCategories"
                    :key="category.id"
                    :value="category.id"
                    >{{ category.title }}</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </div>

            <div class="mt-3" v-if="isBannerImageUser">
              <p class="headText m-0 mb-1">Banner Image Link (Desktop)</p>
              <b-form-input
                placeholder="Enter Banner Image Link"
                v-model="banner_image.desktop_image"
              ></b-form-input>
            </div>
            <div class="mt-3" v-if="isBannerImageUser">
              <p class="headText m-0 mb-1">Banner Image Link (Mobile)</p>
              <b-form-input
                placeholder="Enter Banner Image Link"
                v-model="banner_image.mobile_image"
              ></b-form-input>
            </div>
          </div>
        </div>
        <!-- Right Section End -->
      </div>

      <ProductImageGalleryModal
        ref="EditProductImageModalRef"
        :productImages="currentProduct.productImages"
        @EditVariantImages="SetEditVariantImage($event)"
        @changeEditProductImage="SetEditProductImage($event)"
        @changeEditProductOtherImage="SetEditProductOtherImage($event)"
      />

      <Modal
        size="md"
        id="copyProduct"
        ref="copyProduct"
        title="Duplicate Product"
      >
        <div class="px-4">
          <div class="w-100">
            <input
              type="text"
              v-model="duplicateProduct.title"
              name=""
              class="quizell-form-control px-2 w-100"
              id=""
            />
          </div>
          <div class="d-flex justify-content-center align-items-center mt-4">
            <Button
              :isLoading="duplicateProductLoading"
              @click="DuplicateProduct()"
              >Duplicate</Button
            >
          </div>
        </div>
      </Modal>
    </div>
    <!-- Product URL Modal End-->
    <!-- Duplicate Product Modal -->
    <EditProductFooter
      name="products"
      @delete="DeleteProduct"
      :isLoadingSave="isEditProductLoading"
      :isLoadingDelete="deleteProductLoading"
      @discard="discardChanges()"
      @save="EditProduct"
    ></EditProductFooter>
  </div>
</template>

<script>
import Button from "../Layout/Button.vue";
import Modal from "../Layout/Modal.vue";
import axios from "axios";
import draggable from "vuedraggable";
import EditProductSkeleton from "./Skeletons/EditProductSkeleton.vue";
// import ProductImageGallery from "./ProductImageGallery.vue"
import ProductImageGalleryModal from "./ProductImageGalleryModal.vue";
// import LoaderComp from "../../customize/component/LoaderComp.vue";
import MultiFilter from "../Layout/MultiFilter.vue";
import ProductMediaCard from "./modal/ProductMediaCard.vue";
import globalMixin from "../../../GlobalMixins/getSignedURLMixin.js";
import $ from "jquery";
import { mapGetters } from "vuex";
import Editor from "../../../components/Editor.vue";
import Header from "../Layout/Header.vue";
import EditProductFooter from "../Layout/EditProductFooter.vue";
import { cloneDeep } from "lodash";
export default {
  props: {
    SelectedProduct: Object,
  },
  mixins: [globalMixin],
  components: {
    Header,
    Button,
    Modal,
    EditProductFooter,
    draggable,
    EditProductSkeleton,
    // ProductImageGallery,
    ProductImageGalleryModal,
    // LoaderComp,
    MultiFilter,
    ProductMediaCard,

    Editor,
  },
  data() {
    return {
      deleteProductLoading: false,
      selectedCollections: [],
      allCollections: [],
      allTags: [],
      allVendors: [],
      selectedVendors: null,
      hasVariantAlready: false,
      OptionDragging: false,
      OptionValueDragging: false,
      VariantKey: 0,
      EditView: "VariantView",
      currentProduct: this.SelectedProduct,
      isProductLoading: true,
      fields: [
        {
          key: "image",
          label: "Image",
        },
        {
          key: "title",
          label: "Name",
        },
        {
          key: "price",
          label: "Price",
        },
        {
          key: "compare_at_price",
          label: "Compare Price",
        },
        {
          key: "action",
          label: "",
        },
      ],
      selectedTags: [],
      isEditProductLoading: false,
      selectedVariantImageIndex: null,
      selectedProductImageIndex: null,
      OpenGalleryMode: "",
      selectedPropImage: "",
      isImageAdding: false,
      selectedProductImage: [],
      ImageUrlLink: "",
      isImageDelete: false,
      makeThumbnailSpinner: false,
      productCategories: [],
      selectedBullets: [],
      allBullets: [],
      beforeUpdate: null,
      duplicateProduct: {},
      duplicateProductLoading: false,

      banner_image: {
        type: "url",
        desktop_image: "",
        mobile_image: "",
      },
    };
  },
  mounted() {
    this.getSelectedProduct(this.$route.params.productId);

    this.getProductCategory();
  },
  methods: {
    async AddTag(data) {
      let path = "/tag/save";
      let method = "post";

      await axios[method](path, data)
        .then(() => {
          this.$toasted.show("Tag saved successfully", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        })
        .catch(() => {
          this.$toasted.show("Error occurred.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        });
    },
    discardChanges() {
      this.$nextTick(() => {
        this.currentProduct = cloneDeep({ ...this.beforeUpdate });
      });
    },
    openDuplicateModal() {
      this.$refs.copyProduct.open();
      this.duplicateProduct = { ...this.currentProduct };
      this.duplicateProduct.title = "Copy of " + this.duplicateProduct.title;
    },
    async DuplicateProduct() {
      this.duplicateProductLoading = true;

      await axios
        .post("/duplicateProduct/" + this.duplicateProduct.id, {
          title: this.duplicateProduct.title,
        })
        .then((response) => {
          if (response.data.status) {
            this.$toasted.show("Product has been duplicated", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
            this.$router.push({
              name: "EditProduct",
              params: { productId: response.data.data.id },
              query: this.$route.query,
            });
            this.getSelectedProduct(response.data.data.id);
            this.$refs.copyProduct.close();
          }
        })
        .catch((e) => {
          if (e.response.status == 400) {
            this.$toasted.show(e.response.data.message, {
              theme: "toasted-primary",
              type: "error",
              position: "bottom-center",
              duration: 2000,
            });
          } else {
            this.$toasted.show("An Error Occurred", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
          }
        });

      this.duplicateProductLoading = false;
    },
    async DeleteProduct() {
      this.deleteProductLoading = true;
      try {
        let data = {
          _token: this.GetTokenFromMetaTag,
          ids: [this.currentProduct.id],
        };
        const response = await axios.post(`/removeProducts`, data);
        if (response.status == 200) {
          this.$router
            .push({
              name: "Product",
              query: this.$route.query,
            })
            .then(() => {
              this.$router.go(0);
            });
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured while deleting product", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.deleteProductLoading = false;
      }
    },
    async addBullet(data) {
      let path = "/products/bullets";
      let method = "post";

      await axios[method](path, data)
        .then(() => {
          this.$toasted.show("Bullet description saved successfully", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        })
        .catch(() => {
          this.$toasted.show("Error occurred.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        });
    },
    async getProductCategory() {
      const response = await axios.get(`/product/categories/list`);
      if (response.status == 200) {
        this.productCategories = response.data.data;
      }
    },

    checkFileType(file) {
      let fileType;
      let allowedImageExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      let allowedVideoExtensions = /(\.mp4)$/i;

      if (!allowedImageExtensions.exec(file)) {
        if (allowedVideoExtensions.exec(file)) {
          fileType = "video";
        } else {
          fileType = "unknown";
        }
      } else {
        fileType = "image";
      }

      return fileType;
    },
    async createProductVariantObject() {
      let data = {
        _token: this.GetTokenFromMetaTag,
        compare_at_price: "",
        image: "",
        price: 0,
        title: "",
        product_id: this.currentProduct.id,
      };
      let vm = this;
      await axios
        .post(`/createProductVariant`, data)
        .then((response) => {
          vm.currentProduct.variants.push(response.data);
        })
        .catch((error) => {
          throw error;
        });
    },

    async deleteProductVariant(variant) {
      let index = this.currentProduct.variants.findIndex(
        (x) => x.id == variant.id
      );

      let data = {
        _token: this.GetTokenFromMetaTag,
        product_id: variant.product_id,
        id: variant.id,
      };
      const response = await axios.post(`/deleteProductVariant`, data);
      if (response.status == 200) {
        this.currentProduct.variants.splice(index, 1);
      }
    },
    async getSelectedProduct(productId) {
      this.isProductLoading = true;
      let data = {
        _token: this.GetTokenFromMetaTag,
        id: productId,
      };
      const response = await axios.post(`/getUserProduct`, data);
      if (response.status == 200) {

       
        this.currentProduct = cloneDeep(response.data.data.product);
        this.beforeUpdate = cloneDeep({ ...response.data.data.product });
        this.allCollections = response.data.data.collections.map(
          (collection) => {
            const currentProductCollection =
              this.currentProduct.collections.find(
                (cpCollection) => cpCollection.id == collection.id
              );
            const obj = {
              ...collection,
              pivot: currentProductCollection
                ? currentProductCollection.pivot
                : null,
            };
            if (obj.pivot === null) {
              delete obj.pivot;
            }
            return obj;
          }
        );

        this.allTags = response.data.data.tags.map((tag) => {
          return { title: tag.title };
        });
        this.allVendors = response.data.data.vendors;
        this.allBullets = response.data.data.bullets.map((tag) => {
          return { title: tag.title };
        });

        if (Array.isArray(this.currentProduct.tags))
          this.selectedTags = this.currentProduct.tags.map((bullet) => {
            return { title: bullet };
          });
        else this.selectedTags = [];
        this.selectedVendors = this.currentProduct.vendor;
        if (typeof this.currentProduct.bullet_description != String) {
          this.selectedBullets = this.currentProduct.bullet_description
            ? this.currentProduct.bullet_description.map((bullet) => {
                return { title: bullet };
              })
            : [];
        } else
          this.selectedBullets = this.currentProduct.bullet_description
            .split(",")
            .map((bullet) => {
              return { title: bullet };
            });
        // Collections
        this.selectedCollections = this.currentProduct.collections;

        const banner_image = response.data.data.product.banner_image

        if(banner_image && this.isBannerImageUser){
          this.banner_image = banner_image
        }

        this.isProductLoading = false;
      }
    },
    isJSONValid(jsonString) {
      try {
        JSON.parse(jsonString);
        return true;
      } catch (error) {
        return false;
      }
    },
    OpenEditVaraintImageGallery(openGalleryMode, index, img) {
      this.selectedVariantImageIndex = index;
      this.$refs.EditProductImageModalRef.OpenImageModal(openGalleryMode, img);
    },
    openGallery(openGalleryMode, img) {
      this.$refs.EditProductImageModalRef.OpenImageModal(openGalleryMode, img);
    },
    // Open gallery from other Images
    openGalleryfromOtherImages(openGalleryMode, index, img) {
      this.selectedProductImageIndex = index;
      this.$refs.EditProductImageModalRef.OpenImageModal(openGalleryMode, img);
    },
    // update Variant Image
    SetEditVariantImage(image) {
      this.currentProduct.variants[this.selectedVariantImageIndex].image =
        image;
      this.selectedVariantImageIndex = null;
    },

    // Update Thumbnail Image
    SetEditProductImage(image) {
      this.currentProduct.image = image;
    },
    // Update Product other Image
    SetEditProductOtherImage(image) {
      this.currentProduct.productImages[this.selectedProductImageIndex].image =
        image;
      this.selectedProductImageIndex = null;
    },
    ImageSrc(img) {
      return img.startsWith("uploads") ? `/${img}` : img;
    },

    AddOptions() {
      const obj = new Object();
      obj.name = "";
      obj.value = [
        {
          value: "",
        },
      ];

      this.currentProduct.optionsList.push(obj);
    },
    AddOptionsValue(index) {
      const obj = {
        value: "",
      };
      this.currentProduct.optionsList[index].value.push(obj);
    },
    deleteOption(index) {
      this.currentProduct.optionsList.splice(index, 1);
    },
    deleteOptionValue(index, subIndex) {
      this.currentProduct.optionsList[index].value.splice(subIndex, 1);
    },
    CreateVariants() {
      this.VariantKey += 1;
      let permittedValues = this.currentProduct.optionsList.map((value) =>
        value.value.map((x) => x.value)
      );

      const options = new Object();

      for (let i = 0; i < permittedValues.length; i++) {
        options[i] = permittedValues[i];
      }
      const results = this.getPermutations(options);
      if (results) {
        this.currentProduct.variants = this.getFinalVariantArray(results);
      }
    },

    getPermutations(object, index = 0, current = {}, results = []) {
      const keys = Object.keys(object);
      const key = keys[index];
      const values = object[key];
      for (const value of values) {
        current[key] = value;
        const nextIndex = index + 1;

        if (nextIndex < keys.length) {
          this.getPermutations(object, nextIndex, current, results);
        } else {
          const result = Object.assign({}, current);
          results.push(result);
        }
      }
      return results;
    },
    getFinalVariantArray(results) {
      let Arr = [];

      for (let key in results) {
        if (Object.prototype.hasOwnProperty.call(results, key)) {
          const obj = new Object();
          var value = results[key];

          let name = Object.keys(value).map(function (key) {
            return value[key];
          });
          obj.title = name.join("/");
          obj.price = 0.0;
          obj.compare_at_price = 0.0;
          obj.price = 0;
          obj.image = "";
          obj.id = 0;
          Arr.push(obj);
        }
      }
      return Arr;
    },
    AllProductView() {
      this.$emit("AllProductView");
    },

    async deleteImages() {
      const Ids = this.selectedProductImage.map((x) => x.id);
      this.isImageDelete = true;
      try {
        const data = {
          product_id: this.currentProduct.id,
          image_ids: Ids,
        };
        const response = await axios.post(`/deleteProductImages`, data);
        if (response.status == 200 && response.data.status == "success") {
          let data2 = {
            id: this.$route.params.productId,
          };
          const response = await axios.post(`/getUserProduct`, data2);
          if (response.status == 200) {
            this.currentProduct = response.data.data.product;
            this.isProductLoading = false;
          }
          this.saveStep2();
        } else {
          this.$toasted.show(`Error Occured`, {
            theme: "toasted-primary",
            type: "error",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } catch (error) {
        this.$toasted.show(`Error Occured`, {
          theme: "toasted-primary",
          type: "error",
          position: "bottom-center",
          duration: 2000,
        });
        throw error;
      } finally {
        this.isImageDelete = false;
      }

      // let Arr = [...this.currentProduct.productImages];

      // Arr = Arr.filter(function (obj) {
      //   return !this.has(obj.id);
      // }, new Set(this.selectedProductImage.map((obj) => obj.id)));

      // this.currentProduct.productImages = Arr;
      // this.selectedProductImage = [];
    },
    async saveStep2() {
      if (!this.currentProduct.productImages.length) {
        return true;
      }

      this.isEditProductLoading = true;

      try {
        let data = {
          product_id: this.currentProduct.id,
          image_ids: this.currentProduct.productImages.map((obj) => obj.id),
        };

        const response = await axios.post(
          `${process.env.VUE_APP_API_BASE_URL}storeProductImageOrder`,
          data
        );
        if (response.status == 200) {
          this.$toasted.show("Product Saved ", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          this.getSelectedProduct(this.$route.params.productId);
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured ", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.isEditProductLoading = false;
        this.makeThumbnailSpinner = false;
      }
    },
    MakeThumbnailImage() {
      if (this.selectedProductImage.length == 1) {
        const ID = this.selectedProductImage[0].id;
        const Arr = [...this.currentProduct.productImages];
        Arr.unshift(
          Arr.splice(
            Arr.findIndex((item) => item.id === ID),
            1
          )[0]
        );

        this.currentProduct.productImages = Arr;
        this.selectedProductImage = [];
        this.makeThumbnailSpinner = true;
        this.saveStep2();
      }
    },
    selectAllImage(e) {
      if (e) {
        this.selectedProductImage = this.currentProduct.productImages;
      } else {
        this.selectedProductImage = [];
      }
    },
    async EditProduct() {
      this.isEditProductLoading = true;
      // this.currentProduct.collections=this.currentProduct.collections;

      try {
        const data = {
          _token: this.GetTokenFromMetaTag,
          ...this.currentProduct,
        };

        if (this.isBannerImageUser) {
          data["banner_image"] = this.banner_image;
        }

        const response = await axios.post(`/updateQuizProduct`, data);
        if (response.status == 200) {
          this.$router.push({
            name: "Product",
            query: this.$route.query,
          });
        }

      } catch (error) {
        this.$toasted.show(`Error Occured`, {
          theme: "toasted-primary",
          type: "error",
          position: "bottom-center",
          duration: 2000,
        });
        throw error;
      } finally {
        this.isEditProductLoading = false;
      }
    },
    // Image Upload Functions
    async uploadFile(e) {
      // let  = e.target.files;
      await this.uploadOnServer(e.target.files);
    },
    uploadOnServer(files) {
      if (this.currentProduct.id) {
        let items = [];
        let validImageTypes = [
          "image/gif",
          "image/jpeg",
          "image/png",
          "image/svg+xml",
        ];
        try {
          for (const file of files) {
            let fileType = file.type;
            let fileName = file.name;
            if (validImageTypes.includes(fileType)) {
              let reader = new FileReader();
              reader.onload = async (e) => {
                let obj = {
                  fileName: fileName,
                  fileType: fileType,
                  foderName: "products",
                };
                this.isImageAdding = true;
                let getSignedURLResponse = await this.getSignedUrl(obj);
                if (getSignedURLResponse.data.ok) {
                  let data = getSignedURLResponse.data;
                  let filePath = data.filePath;
                  let postData = {
                    product_id: this.currentProduct.id,
                    image_path: filePath,
                  };
                  this.isImageAdding = true;
                  items.push(postData);
                  let digitalSpacesResponse = await this.UploadSignedURLImage(
                    data,
                    e
                  );

                  if (digitalSpacesResponse.ok) {
                    var elements = digitalSpacesResponse.url.split("?");
                    var fistElement = elements[0];
                    var filePortion = fistElement.split("/").pop();
                    items.forEach(async (element) => {
                      if (element.image_path.includes(filePortion)) {
                        try {
                          let data = element;
                          const finalResponse = await axios.post(
                            `${process.env.VUE_APP_API_BASE_URL}storeProductImage`,
                            data
                          );
                          if (finalResponse.status == 200) {
                            this.currentProduct.productImages = [
                              ...this.currentProduct.productImages,
                              finalResponse.data,
                            ];
                            this.isImageAdding = false;
                          }
                        } catch (error) {
                          if (error) {
                            this.$toasted.show("Error occured", {
                              theme: "toasted-primary",
                              position: "bottom-center",
                              duration: 2000,
                            });
                            // this.isLoading = false;
                            throw error;
                          }
                        }
                      }
                    });
                  } else {
                    this.$toasted.show("Error occured", {
                      theme: "toasted-primary",
                      position: "bottom-center",
                      duration: 2000,
                    });
                    this.isImageAdding = false;
                  }
                } else {
                  this.$toasted.show("Error occured", {
                    theme: "toasted-primary",
                    position: "bottom-center",
                    duration: 2000,
                  });
                  this.isImageAdding = false;
                }
              };

              // Read in the image file as a Blob.
              reader.readAsArrayBuffer(file);
            }
          }
        } catch (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          this.isImageAdding = false;
          throw error;
        }
      } else {
        this.$toasted.show("Error occured", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      }
    },

    async uploadImageLink(url) {
      this.isImageAdding = true;

      try {
        this.isLink = true;
        let data = {
          product_id: this.currentProduct.id,
          image_path: url,
        };

        const response = await axios.post(
          `${process.env.VUE_APP_API_BASE_URL}storeProductImage`,
          data
        );
        if (response.status == 200) {
          this.currentProduct.productImages = [
            ...this.currentProduct.productImages,
            response.data,
          ];
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured ", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.isImageAdding = false;
      }
    },
    OpenImageUrlModal() {
      this.ImageUrlLink = "";
      $("#EditProductURLModal").modal("show");
    },
    closeURLModal() {
      $("#EditProductURLModal").modal("hide");
    },
    updateProductDescription(value) {
      this.currentProduct.description = value;
    },
  },
  computed: {
    userAccountData() {
      return this.getUserAllAccountData.account
        ? this.getUserAllAccountData.account
        : null;
    },
    isBannerImageUser() {
      return process.env.VUE_APP_BEARWBENEFITS_QUIZID.includes(
        this.userAccountData?.id
      );
    },
    backUrl() {
      const routeData = this.$router.resolve({
        name: "Product",
        query: this.$route.query,
      });
      return routeData.href;
    },

    productImages() {
      return this.currentProduct ? this.currentProduct.productImages : [];
    },
    ...mapGetters(["GetTokenFromMetaTag", "getUserAllAccountData"]),
    getFirstImage() {
      if (this.currentProduct.productImages.length) {
        return this.currentProduct.productImages[0];
      } else {
        return null;
      }
    },
    getRandomValue() {
      return Math.floor(Math.random() * (1000 - 11 + 1) + 11);
    },
  },
};
</script>

<style scoped>
.border-radius {
  border-radius: var(--border-radius);
}
.headTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  color: #4b494e;
}
.headText {
  font-size: 16px;
  color: #4d4950;
}
.subHeadText {
  font-size: 12px;
  line-height: 18px;
  color: #18191c;
}
.AddOption {
  color: #ffa201;

  font-size: 14px;
  line-height: 21px;
}

/* Edit Variant Styles */
.leftSection .title {
  font-family: "Poppins";
  font-style: normal;

  font-size: 16px;
  line-height: 24px;

  color: #4d4950;
}
.leftSection .sub-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: #4d4950;
}
.leftSection .variantTitle {
  font-family: "Poppins";
  font-style: normal;

  font-size: 16px;
  line-height: 24px;

  color: #4d4950;
}

.leftSection .VariantContainer {
  height: 100%;
  min-height: 100px;
  max-height: 350px;
  overflow: hidden scroll;
  scroll-behavior: smooth;
}
.leftSection .singleVariant {
  height: 60px;
  border-radius: 6px;
  background: #e0dee2;
}
.leftSection .singleVariant p {
  font-family: "Poppins";
  font-style: normal;

  font-size: 16px;
  line-height: 24px;

  color: #4d4950;
}
.ImageCard .checkboxElement {
  display: none;
}

.ImageCard:hover {
  background: #cccace;
}
.ImageCard:hover .checkboxElement {
  display: block;
}
.deleteProduct {
  background: rgba(192, 21, 21, 0.2);
  border-radius: 6px;
  color: #c01515;
}

.editUrlModalContent {
  border-radius: 6px;
}

.EditImageURLInput {
  border: 0.8px solid #b3afb6;
  box-sizing: border-box;
  border-radius: 6px;
  background: #ffffff;
}
.EditImageURLInput input {
  background: transparent;
  border: none;
  outline: none;
}
.EditImageURLInput input:focus {
  border: none;
  outline: none;
}

.QuantityInput {
  position: relative;
}

.QtyBtn {
  position: absolute;
  top: 35px;
  right: 10px;
  z-index: 100000;
}

.increaseQuantityBtn,
.decreaseQuantityBtn {
  font-size: 7px;
  padding: 0;
  border: none;
  outline: none;
  margin: 0;

  cursor: pointer;
}
.duplicateProducts {
  padding: 7px 12px;
  display: flex;
  gap: 4px;
  border-radius: 6px;
  background: var(--white);
  border: 1px solid var(--border-color);
  color: var(--grey-button);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  transition: 0.3s ease all;
}
.duplicateProducts:hover {
  background: var(--grey-button);
  color: var(--white);
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
